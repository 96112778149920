export const BG_PRIMARY = '#FAF2EA';
export const BG_SECONDARY = '#cb9e93';
export const BG_NAVIGATION = '#8C3437';
export const TEXT_PRIMARY_LIGHT = `#fff`;
export const BG_SECONDARY_DARK = '#c0958a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(203 158 147 / 35%)';
export const TEXT_PRIMARY = '#8C3437';
export const TEXT_SECONDARY = BG_SECONDARY;

export const REVERSE_ELIPS_COLOR = BG_PRIMARY;

export const DEFAULT_BUTTON_PROPS = '';